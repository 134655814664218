/* SCSS Variables */

/*--------------
  Font
  -------------- */
$base-font: system-ui, -apple-system, 'Source Sans Pro', 'Apple SD Gothic Neo', 'Nanum Barun Gothic', 'Nanum Gothic', Verdana, Arial, Dotum, sans-serif;
$code-font: Menlo, Monaco, monospace;
$code-gist-font: Menlo, Monaco, monospace;

$base-font-size: 18px;
$base-font-size-sm: 14px;

$mobile-font-size: 16px;


/*--------------
  Color
  -------------- */
$base-color: $oc-black;
$base-lighten-color: $oc-gray-6;

$background-color: $oc-white;
$background-over-color: $oc-gray-1;

$text-color: $oc-black;
$link-color: $oc-blue-8;
$code-color: $oc-indigo-9;
$code-background-color: $oc-gray-1;
$table-border-color: $oc-gray-2;
$table-background-color: $oc-gray-1;

$divider-color: $oc-gray-1;

/*--------------
  Dark theme Color
  -------------- */
$base-color-dark: $oc-white;
$base-lighten-color-dark: $oc-gray-1;

$background-color-dark: $oc-black;
$background-over-color-dark: $oc-gray-6;

$text-color-dark: $oc-white;
$link-color-dark: $oc-blue-3;
$code-color-dark: $oc-indigo-0;
$code-background-color-dark: $oc-gray-9;
$table-border-color-dark: $oc-gray-8;
$table-background-color-dark: $oc-gray-9;

$divider-color-dark: $oc-gray-9;


/*--------------
  List
  -------------- */

$li-bottom-space: 0.8em;
$li-bullets-width: 1.7em;
$li-line-height: 1.55;

$ul-bullets-font: inherit;
$ul-bullets-font-size: 1.2em;
$ul-bullets-font-line-height: 1.2;
$ul-bullets-right-space: 0.65em;

$ol-bullets-font: inherit;
$ol-bullets-font-size: 1em;
$ol-bullets-font-line-height: inherit;
$ol-bullets-right-space: 0.65em;

$li-child-size-ratio: 0.95;

$dt-width: 180px;
$dt-dd-space: 20px;
$dd-position: $dt-width+$dt-dd-space
