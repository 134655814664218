.fa { background: url('../icons/icons.png') no-repeat top left; width: 24px; height: 24px; display: inline-block; } 
@media(prefers-color-scheme: dark){
  .fa {
    -webkit-filter: invert(1);
    filter: invert(1);
  }
}
.fa.fa-envelope { background-position: 0 0; }
.fa.fa-facebook { background-position: -24px 0; }
.fa.fa-github { background-position: -48px 0; }
.fa.fa-instagram { background-position: -72px 0; }
.fa.fa-reddit { background-position: -96px 0; }
.fa.fa-rss { background-position: -120px 0; }
.fa.fa-rss-square { background-position: -144px 0; }
.fa.fa-twitter { background-position: -168px 0; }
.fa.fa-youtube { background-position: -192px 0; }
.fa.fa-youtube-play { background-position: -216px 0; }
